<!-- **************************************-->
<!-- **************Preloader**************-->
<!-- **************************************-->
<div class="preloader">
  <div class="ball-rotate">
    <div></div>
  </div>
</div>

<!-- **************************************-->
<!-- **************Register Page**************-->
<!-- **************************************-->
<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-12 col-lg-10">
      <div class="auth-screen">
        <!-- Register Get Started Text -->
        <div class="auth-content">
          <!-- Logo -->
          <figure class="text-center mb-5">
            <img
              alt="English Coffee"
              class="img-header-experimental"
              src="../../../assets/images/logo/logo-english-coffee.png"
            />
          </figure>

          <div class="welcome-text" *ngIf="currentStep == 1">
            <h3>Olá! Vamos começar</h3>
            <p class="mb-0">
              Precisamos de algumas informações para agendar sua aula
              experimental.
            </p>
          </div>
          <div class="welcome-text" *ngIf="currentStep == 2">
            <h3>Dias e horários de preferência</h3>
            <p class="mb-0">
              Escolha sua preferência de horários para realizar a aula
              experimental.
            </p>
          </div>
          <div class="row m-0" *ngIf="currentStep == 1">
            <form
              class="needs-validation form-row"
              [formGroup]="contactDataForm"
            >
              <div class="col-md-12 mb-3 mt-3 p-0">
                <label for="formName"
                  >Nome <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome"
                  id="formName"
                  formControlName="name"
                  maxlength="80"
                  [ngClass]="{
                    'is-invalid': !validContact && f_contact?.name?.errors
                  }"
                  required
                />
                <div
                  *ngIf="!validContact && f_contact?.name?.errors"
                  class="invalid-feedback text-left"
                >
                  <div *ngIf="f_contact?.name?.errors?.required">
                    Por favor, informe o campo nome.
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3 pl-0">
                <label for="formEmail"
                  >Informe seu melhor e-mail
                  <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  placeholder="E-mail"
                  id="formEmail"
                  formControlName="email"
                  maxlength="100"
                  [ngClass]="{
                    'is-invalid': !validContact && f_contact?.email?.errors
                  }"
                  required
                />
                <div
                  *ngIf="!validContact && f_contact?.email?.errors"
                  class="invalid-feedback text-left"
                >
                  <div *ngIf="f_contact?.email?.errors?.required">
                    Por favor, informe o campo e-mail.
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3 pr-0">
                <label for="formPhone"
                  >WhatsApp <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Telefone"
                  id="formPhone"
                  formControlName="phone"
                  (change)="setPhone($event)"
                  placeholder="(00) 00000-0000"
                  maxlength="20"
                  [textMask]="{ mask: phoneMask }"
                  [ngClass]="{
                    'is-invalid': !validContact && f_contact?.phone?.errors
                  }"
                  required
                />
                <div
                  *ngIf="!validContact && f_contact?.phone?.errors"
                  class="invalid-feedback text-left"
                >
                  <div *ngIf="f_contact?.phone?.errors?.required">
                    Por favor, informe o campo telefone.
                  </div>
                  <div *ngIf="f_contact?.phone?.errors?.invalid">
                    Por favor, informe um telefone válido.
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3 pl-0">
                <label for="formTarget"
                  >Objetivo <span class="text-danger">*</span></label
                >
                <select
                  id="formTarget"
                  class="form-control"
                  formControlName="target"
                  placeholder="Selecione um objetivo"
                  [ngClass]="{
                    'is-invalid': !validContact && f_contact?.target?.errors
                  }"
                  required
                >
                  <option value="0" selected>Qual é o seu objetivo?</option>
                  <option value="1">Carreira e negócios</option>
                  <option value="2">Aulas para crianças</option>
                  <option value="3">Morar no exterior</option>
                  <option value="4">Cultura, viagens e hobby</option>
                  <option value="5">Outro</option>
                </select>
                <div
                  *ngIf="!validContact && f_contact?.target?.errors"
                  class="invalid-feedback text-left"
                >
                  <div *ngIf="f_contact?.target?.errors?.required">
                    Por favor, informe o campo objetivo.
                  </div>
                  <div *ngIf="f_contact?.target?.errors?.min">
                    Por favor, selecione um item.
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3 pr-0">
                <label for="formLevel"
                  >Nível <span class="text-danger">*</span></label
                >
                <select
                  id="formLevel"
                  class="form-control"
                  formControlName="level"
                  placeholder="Selecione um nível"
                  [ngClass]="{
                    'is-invalid': !validContact && f_contact?.level?.errors
                  }"
                  required
                >
                  <option value="0" selected>
                    Qual é o seu nível de inglês?
                  </option>
                  <option value="1">Sou iniciante</option>
                  <option value="2">Sou zero, não sei nada de inglês</option>
                  <option value="3">Sei o básico</option>
                  <option value="4">
                    Sou intermediário, consigo conversar em alguns casos
                  </option>
                  <option value="5">Sou avançado, preciso melhorar</option>
                  <option value="6">Outro</option>
                </select>
                <div
                  *ngIf="!validContact && f_contact?.level?.errors"
                  class="invalid-feedback text-left"
                >
                  <div *ngIf="f_contact?.level?.errors?.required">
                    Por favor, informe o campo nível.
                  </div>
                  <div *ngIf="f_contact?.level?.errors?.min">
                    Por favor, selecione um item.
                  </div>
                </div>
              </div>
              <div class="col-md-12 ml-auto text-right" *ngIf="!validContact">
                <hr />
                <div
                  class="alert alert-warning alert-dismissible text-left fade show mt-3"
                  role="alert"
                >
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true"><i class="fa fa-times"></i></span>
                  </button>
                  {{ returnMessageContact }}
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <button
                  type="submit"
                  class="btn btn-primary pull-right"
                  (click)="onSubmitContact(3)"
                  [disabled]="contactSubmitted"
                >
                  <span *ngIf="!contactSubmitted">Continuar</span>
                  <span *ngIf="contactSubmitted" class="pr-2"
                    >Buscando datas disponíveis</span
                  >
                  <i class="fa fa-arrow-right" *ngIf="!contactSubmitted"></i>
                  <span
                    class="spinner-border spinner-border-sm mr-1"
                    *ngIf="contactSubmitted"
                  ></span>
                </button>
              </div>
            </form>
          </div>
          <div class="row" *ngIf="currentStep == 2">
            <div class="col-12">
              <h4 class="mb-3 mt-3">Horários</h4>
              <label for="formTime">Madrugada/Manhã</label>
              <div class="row">
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="dawn_0_3"
                          (click)="setTimePrefer($event)"
                        >
                          0h - 3h
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="dawn_3_6"
                          (click)="setTimePrefer($event)"
                        >
                          3h - 6h
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="morning_6_9"
                          (click)="setTimePrefer($event)"
                        >
                          6h - 9h
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="morning_9_12"
                          (click)="setTimePrefer($event)"
                        >
                          9h - 12h
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <label for="formTime">Tarde/Noite</label>
              <div class="row">
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="afternoon_12_15"
                          (click)="setTimePrefer($event)"
                        >
                          12h - 15h
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="afternoon_15_18"
                          (click)="setTimePrefer($event)"
                        >
                          15h - 18h
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="night_18_21"
                          (click)="setTimePrefer($event)"
                        >
                          18h - 21h
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="night_21_0"
                          (click)="setTimePrefer($event)"
                        >
                          21h - 0h
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4 class="mb-3 mt-3">Dias da semana</h4>
              <div class="row">
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="dayOfWeek_0"
                          (click)="setDayPrefer($event)"
                        >
                          Dom
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="dayOfWeek_1"
                          (click)="setDayPrefer($event)"
                        >
                          Seg
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="dayOfWeek_2"
                          (click)="setDayPrefer($event)"
                        >
                          Ter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="dayOfWeek_3"
                          (click)="setDayPrefer($event)"
                        >
                          Qua
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="dayOfWeek_4"
                          (click)="setDayPrefer($event)"
                        >
                          Qui
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="dayOfWeek_5"
                          (click)="setDayPrefer($event)"
                        >
                          Sex
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--==== Single Pricing Box =====-->
                <div
                  class="col mb-30 aos-init aos-animate"
                  data-aos="zoom-in"
                  data-aos-duration="300"
                >
                  <div class="pricing-card-box-2 box-shadow">
                    <div class="pricing-body">
                      <div class="pricing-footer">
                        <button
                          class="btn btn-block btn-light btn-check text-center"
                          id="dayOfWeek_6"
                          (click)="setDayPrefer($event)"
                        >
                          Sáb
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-12 ml-auto text-right"
              *ngIf="!validDatetimePrefer"
            >
              <hr />
              <div
                class="alert alert-warning alert-dismissible text-left fade show mt-3"
                role="alert"
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true"><i class="fa fa-times"></i></span>
                </button>
                {{ returnMessageDatetimePrefer }}
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="backStep(1)"
              >
                <i class="fa fa-arrow-left"></i> Voltar
              </button>
              <button
                type="button"
                class="btn btn-primary pull-right"
                (click)="onSubmitDatetimePrefer(3)"
                [disabled]="datetimePreferSubmitted"
              >
                <span *ngIf="!datetimePreferSubmitted">Continuar</span>
                <span *ngIf="datetimePreferSubmitted" class="pr-2"
                  >Analisando agenda</span
                >
                <i
                  class="fa fa-arrow-right"
                  *ngIf="!datetimePreferSubmitted"
                ></i>
                <span
                  class="spinner-border spinner-border-sm mr-1"
                  *ngIf="datetimePreferSubmitted"
                ></span>
              </button>
            </div>
          </div>
          <div class="row" *ngIf="currentStep == 3">
            <div class="col-12">
              <h4 class="mb-3 mt-3 d-md-block">
                Agendas disponíveis
                <button
                  type="button"
                  class="btn btn-primary pull-right d-none d-lg-block"
                  (click)="onSubmitSchedule(4)"
                >
                  Agendar minha aula
                </button>
              </h4>
              <label for="formTime"
                >Selecione o melhor dia/horário para você!</label
              >
              <div class="row">
                <div class="col-md-12 mb-3">
                  <button
                    type="button"
                    class="btn btn-primary pull-right d-block d-lg-none"
                    (click)="onSubmitSchedule(4)"
                  >
                    Agendar minha aula
                  </button>
                </div>
                <div
                  *ngFor="let iDate of experimentalClassArray; index as i"
                  class="col-md-6 col-lg-4 cursor-pointer"
                  data-aos="fade-up"
                  [ngStyle]="{ 'animation-delay': i + 's' }"
                  (click)="
                    setExperimentalClass(i, iDate, 'schedule_experimental_' + i)
                  "
                >
                  <div
                    class="service-card border-img"
                    [id]="'schedule_experimental_' + i"
                  >
                    <div class="icon-img">
                      <i class="icon-calendar fa-2x"></i>
                    </div>
                    <div class="service-body">
                      <h6 class="service-title">
                        {{ formatDateTime(iDate) }}<br /><small
                          class="text-primary"
                          >{{ getDayOfWeek(iDate) }}</small
                        >
                      </h6>
                      <p class="service-description">Aula Experimental</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-12 ml-auto text-right"
              *ngIf="!validPostSchedule"
            >
              <hr />
              <div
                class="alert alert-warning alert-dismissible text-left fade show mt-3"
                role="alert"
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true"><i class="fa fa-times"></i></span>
                </button>
                {{ returnMessagePostSchedule }}
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="backStep(1)"
              >
                <i class="fa fa-arrow-left"></i> Voltar
              </button>
            </div>
          </div>
          <div class="row mt-5" *ngIf="currentStep == 4">
            <div
              class="col-md-6 mb-30"
              data-aos="fade-up"
              data-aos-duration="400"
              data-aos-delay="600"
            >
              <img
                src="../../../assets/images/app/english-coffee-meet.jpg"
                alt="Visão de uso da aplicação"
              />
            </div>
            <div
              class="col-md-6 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-duration="600"
            >
              <div class="section-header gred-bottom-line">
                <h2>
                  Aula Pré-Agendada com <span class="text-info">SUCESSO</span>
                </h2>
              </div>
              <div class="clearfix">
                <p class="mb-15">
                  Você está um passo mais perto de aprimorar suas habilidades no
                  idioma inglês com a English Coffee.
                  <br />
                  <strong> Em breve, você receberá dois e-mails: </strong>
                  <span class="mb-15">
                    1º e-mail: Confirmação de agendamento com dia e horário;
                  </span>
                  <span class="mb-15">
                    2º e-mail: Login e Senha para acessar a área exclusiva;
                  </span>
                </p>
                <p class="mb-15">
                  Lembre-se que ao acessar o segundo e-mail, você deve clicar
                  em: "Clique aqui para acessar" e será direcionado para inserir
                  seu login e senha recebidos anteriormente.
                </p>
                <p class="mb-15">
                  Fique atento à sua
                  <strong class="fw-700 text-primary"
                    >caixa de entrada, de seu e-mail,</strong
                  >
                  para garantir que você não perca nenhuma informação
                  importante. Estamos ansiosos para ajudá-lo a alcançar seus
                  objetivos de aprendizado de inglês!
                </p>
                <span></span>
                <p class="mb-15">
                  Atenciosamente,
                  <br /><strong>Equipe English Coffee</strong>
                </p>
                <div class="d-block">
                  <a type="button" class="btn btn-primary btn-block" href="/"
                    >Finalizar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
