import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home';
import { ComingSoonComponent } from './components/coming-soon';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ExperimentalClassComponent } from './components/experimental-class/experimental-class.component';
// import { LoginComponent } from './login';
// import { RegisterComponent } from './register';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'coming', component: ComingSoonComponent, pathMatch: 'full' },
    { path: 'thank-you', component: ThankYouComponent, pathMatch: 'full' },
    { path: 'experimental-class', component: ExperimentalClassComponent, pathMatch: 'full' },
    // { path: 'login', component: LoginComponent },
    // { path: 'register', component: RegisterComponent },

    // otherwise redirect to home
    //{ path: '**', redirectTo: '' }

    //Wild Card Route for 404 request
    { path: '**', pathMatch: 'full', component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
// export const appRoutingModule = RouterModule.forRoot(routes);
