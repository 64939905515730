(function ($) {
    "use strict";

    /**
    * ----------------------------------------------
    * Home banner carousel call
    * ----------------------------------------------
    */
    homeSlider();

    /**
     * ----------------------------------------------
     * Nav Scroll active section class call
     * ----------------------------------------------
     */
    windowScroll();
    
    /**
    * ----------------------------------------------
    * service slider call
    * ----------------------------------------------
    */

    serviceSlider()
   
    /**
     * ----------------------------------------------
     * Testimonial slider call
     * ----------------------------------------------
     */
    testimonialSlider();

    /**
    * ----------------------------------------------
    * Slick Slider Call
    * ----------------------------------------------
    */
     slickSlider();

    /**
    * ----------------------------------------------
    * SVG Animation cALL
    * ----------------------------------------------
    */
    svgAnimation();

})(jQuery);

/**
* ----------------------------------------------
* SVG Animation 
* ----------------------------------------------
*/
function svgAnimation(){
    if($(".svg-animation").length){
        new Vivus('my-svg1', {
            type: 'sync', 
            duration: 100,
        }, function(){
            setTimeout(function(){
                $("#my-svg1").parents('.svg-object').next().addClass('show');
                $("#my-svg2").parents('.svg-object').addClass('show')
            new Vivus('my-svg2', {type: 'sync', duration: 100},
                function(){
                    setTimeout(function(){
                        $("#my-svg2").parents('.svg-object').next().addClass('show');
                        $("#my-svg3").parents('.svg-object').addClass('show')
                        new Vivus('my-svg3', {type: 'sync', duration: 100},
                        function(){
                            setTimeout(function(){
                                $("#my-svg3").parents('.svg-object').next().addClass('show');
                                $("#my-svg4").parents('.svg-object').addClass('show')
                                new Vivus('my-svg4', {type: 'sync', duration: 100});
                            }, 100)
                        }
                        );
                    }, 100)
                }
                );
            }, 100)
        })
    }
}


/**
* ----------------------------------------------
* Skills progress bar
* ----------------------------------------------
*/
function skillsProgress(){
    $(".skills-progress span").each(function(){
        var dataValue = $(this).attr("data-value");
        $(this).width(dataValue).addClass('show-width');
    })
}

/**
* ----------------------------------------------
* owl-carousel home-carousel
* ----------------------------------------------
*/
function homeSlider() {
    if($(".slider-container").length){
        $(".slider-container .owl-carousel").owlCarousel({
            animateIn: "fadeIn",
            animateOut: "fadeOut",
            mouseDrag:false,
            items: 1,
            dots: true,
            autoplay: false,
            nav: false,
            navSpeed: 1000,
            autoplaySpeed: 1500,
            margin: 0,
            loop: true,
            navText:["<i class='icon-arrow-left'></i>","<i class='icon-arrow-right'></i>"]
        });
    }
}

/**
 * ----------------------------------------------
 * Nav Scroll active section class 
 * ----------------------------------------------
 */
// function windowScroll() {
//     $(window).on('scroll', function () {
//         var scrollPos = $(this).scrollTop();
//         if (scrollPos >= 20) {
//             $(".header").addClass("fixed-nav");
//             $(".header .navbar-brand img").attr("src", "assets/images/logo-blue.png");
//         } else {
//             $(".header").removeClass("fixed-nav");
//             $(".header .navbar-brand img").attr("src", "assets/images/logo.png");
//         }
//     })
// }

/**
 * ----------------------------------------------
 * Nav Scroll active section class 
 * ----------------------------------------------
 */
 function windowScroll() {
    $(window).on('scroll', function () {
        var scrollPos = $(this).scrollTop();
        if($(".bottom-nav").length){
            if (scrollPos >= $(".banner-slide").height() - $(".bottom-nav").outerHeight()) {
                $(".bottom-nav").addClass("fixed-nav");
            } else {
                $(".bottom-nav").removeClass("fixed-nav");
            }
        }
        else{
            if (scrollPos >= 20) {
                $(".header").addClass("fixed-nav");
                $(".header .navbar-brand img").attr("src", "assets/images/logo-blue.png");
            } else {
                $(".header").removeClass("fixed-nav");
                $(".header .navbar-brand img").attr("src", "assets/images/logo.png");
            }
        }
        skillsProgress();
        // if((scrollPos > $("#about").offset().top - 50) && (scrollPos <  $("#courses").offset().top + $("#courses").height())){
        //     skillsProgress();
        // }
    })
}

/**
* ----------------------------------------------
* Testimonial slider
* ----------------------------------------------
*/
function testimonialSlider(){
    $(".testimonials-slider-3").owlCarousel({
        items: 1,
        slideBy: 2,
        dots: true,
        autoplay: true,
        nav: false,
        smartSpeed: 1000,
        autoplaySpeed: 3000,
        autoplayTimeOut: 6000,
        margin: 30,
        loop: true,
    });
}

/**
* ----------------------------------------------
* service slider
* ----------------------------------------------
*/
function serviceSlider(){
    var serviceSlider = $(".services-slider").owlCarousel({
        items: 3,
        dots: false,
        autoplay: false,
        nav: true,
        smartSpeed: 800,
        autoplaySpeed: 2000,
        autoplayTimeOut: 5000,
        margin: 10,
        loop: false,
        navText:["<i class='icon-arrow-left-circle'></i>","<i class='icon-arrow-right-circle'></i>"],
        responsive: {
            // breakpoint from 0 up
            0: {
                items: 1
            },
            // breakpoint from 480 up
            480: {
                items: 1
            },
            // breakpoint from 768 up
            768: {
                items: 2
            },
            991: {
                items: 3
            },
            1100:{
                items: 3,
                stagePadding: 100,
            }
        },
    });
}


/**
* ----------------------------------------------
* Slick Slider
* ----------------------------------------------
*/
function slickSlider(){
    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.slider-nav'
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        arrows: false,
        dots: true,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]
    });
}

$('[data-toggle="buttons"] .btn').on('click', function () {
    
    // toggle style
    $(this).toggleClass('btn-success btn-danger active');
    
    // toggle checkbox
    var $chk = $(this).find('[type=checkbox]');
    $chk.prop('checked',!$chk.prop('checked'));
    
    return false;
});