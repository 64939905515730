import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExperimentalClassPostSchedule, ResponseResult } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ExperimentalClassService {
  private route: string = 'schedule';
  private controller: string = 'task';
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  teste() {
    return this.http.get<any>(`${environment.urlAPI}/auth/account/Anonymous`);
  }

  fixAvailabilities(dateTimeRef: string): Observable<any> {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/fixAvailabilities/${dateTimeRef}`
    );
  }

  availabilities(days: Array<string>, times: Array<string>): Observable<any> {
    let str_days = days.toString();
    let str_times = times.toString();
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/availabilities/days/${str_days}/time/${str_times}`
    );
  }

  postSchedule(
    eClassPostSchedule: ExperimentalClassPostSchedule
  ): Observable<any> {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/postSchedule`,
      eClassPostSchedule
    );
  }
}
