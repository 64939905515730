import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({ selector: 'sd-header', templateUrl: './header.component.html' })
export class HeaderComponent {
  isCollapsed = true;

  redirectAdmin() {
    localStorage.clear();
    setTimeout(()=>{
      window.location.href = `${environment.adminLogin}`;
    }, 500);
  }

  collapse() {
    var navbarNav = document.getElementById('navbarNav');
    var btnNavbar = document.getElementById('btnNavbar');
    if (navbarNav?.className == 'navbar-collapse collapse show') {
      setTimeout(() => {
        navbarNav.classList.remove('show');
        btnNavbar.classList.remove('collapsed');
        btnNavbar.classList.add('collapsed');
      }, 600);
    }
  }
}