export class ExperimentalClassContact {
    public name: string;
    public email: string;
    public phone: string;
    public target: number;
    public level: number;

    constructor(contentResponse: any) {
        this.name = contentResponse.name;
        this.email = contentResponse.email;
        this.phone = contentResponse.phone;
        
        if (contentResponse.target) {
            this.target = Number.parseInt(contentResponse.target);
        }
        if (contentResponse.level) {
            this.level = Number.parseInt(contentResponse.level);
        }
    }
}
