<!--==== Close Sidebar Button =====-->
<div class="close-sidebar">
    <button>
        <span></span>
        <span></span>
    </button>
</div>
<!--==== Sidebar Nav =====-->
<nav class="sidebar-nav">
    <h4>LetStart</h4>
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-index="1" href="#home">
                Home
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-index="2" href="#about-us">
                About Us
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-index="3" href="#works">
                Works
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-index="4" href="#pricing">
                Pricing
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-index="5" href="#team">
                Team
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-index="6" href="#blog">
                Blog
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-index="7" href="#contact-us">
                Contact Us
            </a>
        </li>
    </ul>
</nav>
<!--==== Sidebar Footer =====-->
<div class="sidebar-footer">
    <!--==== Sidebar Footer Social Links =====-->
    <div class="social-link social-white">
        <a href="#" class="fb"><i class="fa fa-facebook"></i></a>
        <a href="#" class="tw"><i class="fa fa-twitter"></i></a>
        <a href="#" class="ln"><i class="fa fa-linkedin"></i></a>
        <a href="#" class="gplus"><i class="fa fa-google-plus"></i></a>
        <a href="#" class="dribble"><i class="fa fa-dribbble"></i></a>
    </div>
    <!--==== Sidebar Footer Copyright Text =====-->
    <div class="copyright">
        <p>© Copyright 2020. MatrrDigital</p>
    </div>
</div>