<div class="container">
    <!--==== Section Heading Text =====-->
    <div class="section-header">
        <h2><small class="text-primary">Alunos Realizados</small> Depoimentos</h2>
        <p class="subtitle">
            O aluno da English Coffee pode escolher o pacote que mais identifica ao seu perfil, porém todos aplicamos 100% do passo a passo do método.
        </p>
    </div>
    <div class="row">
        <!--==== Single Pricing Box =====-->
        <div class="col-md-12 mb-30">
            <div class="slider-for">
                <div class="item">
                    <p>O método da English Coffee me trouxe muita confiança para usar o meu inglês em todo e qualquer lugar. 
                        As aulas são dinâmicas, com muita conversa e sobre qualquer assunto. 
                        A partir disso pude desenvolver todo o vocabulário das aulas no meu intercâmbio e 
                        consequentemente numa entrevista de emprego da qual tive que usar meu inglês, 
                        fui selecionada e hoje tudo que aprendo nas aulas coloco em prática no meu dia a dia. 
                        Eles trabalham não só com o seu inglês, mas também com toda desenvoltura necessária para que 
                        possamos mostrar aquilo que sabemos, sem medo, com muito incentivo e profissionalismo. 👏🏽📈🧡</p>
                </div>
                <div class="item">
                    <p>Sempre estudei inglês por obrigação, até conhecer a English Coffee. Passei antes por duas escolas, 
                        onde parava e voltava, pois não tinha prazer pelo estudo, a gramática não me interessava muito, 
                        assim não via meu avanço e cansava novamente. Hoje na English Coffee é diferente, o foco é voltado diretamente na conversação, 
                        que é o principal motivo do ser humano buscar uma nova língua. Durante a semana vc estuda o idioma através de vídeos, 
                        os quais vão preparando seu vocabulário e pronúncia para a próxima aula, quanto mais vc assiste, mais te instiga a estudar, 
                        pois durante a aula vc é conduzido a praticar seu novo vocabulário, falando sobre sua semana e também sobre aquilo aprendeu de novo. 
                        Não esquecendo tbm que as aulas são divertidas, dinâmicas e leves.</p>
                </div>
                <div class="item">
                    <p>O inglês sempre foi uma dificuldade para mim de aprender, não sou o tipo que fica lendo textos 
                        e textos e a conversação e a dinâmica do english coffee foi a solução idela pro meu dia a dia. 
                        Perco a timidez, treino durante toda a aula minha conversação e tem me ajudado demais com experiências e dinâmica! 
                        Já melhorei muito e sei que vou chegar ainda mais longe, obrigada!!!</p>
                </div>
                <div class="item">
                    <p>Sou mãe de 3 filhos pequenos, esposa, dona de casa, enfermeira, trabalho em um hospital durante a noite, e ainda sou pastora de uma igreja. 
                        Se com essa rotina louca consigo fazer as aulas de inglês, tenho certeza que vc tbm consegue. Aulas dinâmicas, 
                        metodologia prática, professores sensacionais! Em poucos meses de curso já consigo assistir séries sem legenda em português. 
                        Eu amo a English Coffee!!!</p>
                </div>
            </div>
            <div class="slider-nav">
                <div class="meta-data">
                    <div class="author-image">
                        <img src="assets/images/testimonials/Vanessa-Ferreira-Silva.jpeg" alt="Vanessa Ferreira da Silva" />
                    </div>
                    <h6>Vanessa Ferreira da Silva</h6>
                </div>
                <div class="meta-data">
                    <div class="author-image">
                        <img src="assets/images/testimonials/Jessica-Vanessa-Weise.jpeg" alt="Jéssica Vanessa Weise" />
                    </div>
                    <h6>Jéssica Vanessa Weise</h6>
                </div>
                <div class="meta-data">
                    <div class="author-image">
                        <img src="assets/images/testimonials/Julia-Helen-Aguiar.jpeg" alt="Júlia Helen aguiar" />
                    </div>
                    <h6>Júlia Helen aguiar</h6>
                </div>
                <div class="meta-data">
                    <div class="author-image">
                        <img src="assets/images/testimonials/Leticia-Monteiro-Silva.jpeg" alt="Letícia Monteiro da Silva" />
                    </div>
                    <h6>Letícia Monteiro da Silva</h6>
                </div>
            </div>
        </div>
    </div>
    <!--==== Important 1 =====-->
    <div class="row">
        <div class="col-md-12 mb-30" data-aos="zoom-in" data-aos-duration="300">
            <p class="subtitle">
                Como não trabalhamos com a gramática solta e, sim, com o Inglês Contextualizado, 
                temos uma grade de evolução, ou seja, habilidade que o a aluno adquire em cada 6 meses. Veja abaixo:
            </p>
        </div>
    </div>
</div>
