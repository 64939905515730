import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { EnumTypeResponse } from 'src/app/enum/EnumTypeResponse';
import { Router } from '@angular/router';
import { Contact } from 'src/app/model';
import { ContactService } from 'src/app/services';

@Component({
  selector: 'sd-contact-us-form',
  templateUrl: './contact-us-form.component.html',
})
export class ContactUsFormComponent {
  public contactForm: any;
  submitted = false;
  //private returnUrl: string = '/';
  private thankYouUrl: string = 'thank-you';
  public validContact: boolean;
  public returnMessage = '';
  public msgReturnSuccess: boolean = false;
  private contact: Contact;
  public dataReturn: any;
  public phoneMask: Array<string | RegExp>;
  public contactSubmitted: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private contactService: ContactService
  ) {
    // Initialize the register form
    this.onInitForm();
    this.phoneMask = [
      '(',
      /[1-9]/,
      /[1-9]/,
      ')',
      ' ',
      /\d?/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }

  onInitForm() {
    this.validContact = true;
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      message: [''],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.contactForm.controls;
  }

  checkFormValid(): boolean {
    return this.f.name.value && this.f.email.value && this.f.phone.value;
  }

  onSubmit() {
    this.submitted = true;
    this.validContact = true;
    this.contactForm.disable();

    // Errors
    this.returnMessage = '';

    // stop here if form is invalid
    if (this.contactForm.invalid || !this.checkFormValid()) {
      this.submitted = false;
      this.validContact = false;
      this.contactForm.enable();
      this.returnMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.contact = new Contact(this.contactForm.value);

    if (this.contact.message == '') {
      this.contact.message = '<< Em branco >>';
    }

    this.contactService
      .sendContact(this.contact)
      .pipe(first())
      .subscribe(
        (dataReturn: any) => {
          if (dataReturn.typeResponse == EnumTypeResponse.Success) {
            this.router.navigate([this.thankYouUrl]);
          } else {
            this.msgReturnSuccess = false;
            this.validContact = this.msgReturnSuccess;
            this.returnMessage = dataReturn.message;
            this.contactForm.enable();
            if (this.msgReturnSuccess) {
              this.contactForm.reset();
            }
          }
          this.submitted = false;
        },
        (error: any) => {
          this.submitted = false;
          this.contactForm.enable();
          this.returnMessage =
            'Ocorreu um erro ao realizar o envio dos dados. Tente novamente!';
        }
      );
  }
}
