<!-- **************************************-->
<!-- **************Preloader**************-->
<!-- **************************************-->
<div class="preloader" *ngIf="!loaded" style="background: black;opacity: 0.3;">
    <div class="ball-grid-pulse">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<!-- **************************************-->
<!-- **************Scroll Top Button**************-->
<!-- **************************************-->
<div class="scroll-top-btn" data-scroll-goto="0">
    <a href="#home" class="scroll-top">
        <span class="scroll-top-icon"></span>
    </a>
</div>
<!-- **************************************-->
<!-- **************Main Page Wrapper**************-->
<!-- **************************************-->
<div class="page-wrapper">
    <!--==== Full Screen =====-->
    <div class="overlay opacity-6"></div>
    <div class="fullscreen bg-coming-soon no-header" style="overflow: hidden auto;">
        <div class="row">
            <!--==== Center coloumn vertically and horizontally =====-->
            <div class="col-12 d-flex justify-content-center white-text" style="position: relative;">
                <!--==== Coming soon Content =====-->
                <div class="coming-soon-content" style="margin: auto; position: absolute;">
                    <div class="mt-50 mb-50">
                        <div class="logo">
                            <img src="../../../assets/images/logo/logo-english-coffee.png" alt="English Coffee" style="width: 150px !important;">
                        </div>
                    </div>
                    <div class="cd100">
                        <div class="row">
                            <!--==== Single Counter =====-->
                            <div class="col-12">
                                <!--==== Home Banner Text =====-->
                                <div class="home-slide-content white-text pb-2" style="padding-top: 0 !important;">
                                    <!--==== Home Banner Heading Text =====-->
                                    <h1 class="font-weight-light">Obrigado pela mensagem!</h1>
                                    <br>
                                    <h4 class="font-weight-light">Entraremos em contato com você o mais breve possível.</h4>
                                    <br>
                                    <a href="/" class="btn btn-primary btn-lg text-center">
                                        <i class="fa fa-arrow-left fa-1x pl-15"></i> Voltar para o site
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
